export const ProducersInfo = [
    {
        id:"1",
        name: 'Pascal Aufranc',
        picture:'./images/pascal_aufranc-4.jpg',
        info:'"Am șansa de a lucra parcele pe dealuri abrupte si frumoase, ' +
        'încerc să fac vinuri cu caracter și expresie unice."' 
    },
    {
        id:"2",
        name: 'Jean-Michel Dupré',
        picture:'./images/jean_michel_dupre-25.jpg',
        info:'"Am mai multa incredere, astazi sunt mandru ca am parcele de vita de vie veche remarcabila. Vreau sa le vinific separat pentru a pastra magia terroir-ului"'
    },
    {
        id:"3",
        name: 'Lucien Lardy',
        picture:'./images/lucien_lardy-2.jpg',
        info:'"Am un caracter puternic care ma face sa merg mereu inainte, cu multa vointa, un numele standardelor inalte si al dragostei pentru munca bine facuta."' 
    },
    {
        id:"4",
        name: 'Robert Perroud',
        picture:'./images/robert_perroud-2.jpg',
        info:'"Profesia de viticultor in familia mea dateaza din perioada revolutie franceze. Cu o importanta constiinta de mediu, ma adaptez la parcela din panta care necesita gandire si curaj fizic."' 
    },
    {
        id:"5",
        name: 'Emmanuel Fellot',
        picture:'./images/emmanuel_fellot-12.jpg',
        info:'"Viticultor din tata in fiu din 1829, este o fericire pura sa poti trai si sa perpetuezu in continuare traditia viticultorilor din Beaujolais, unde atmosfera este autentica."' 
    },
    {
        id:"6",
        name: 'Bernard Hudelot',
        picture:'./images/BernardHudelot.png',
        info:"Figură iconică și aproape legendară a Burgundiei, Bernard Hudelot, personaj unic, Vinificator, oenolog, profesor de" +
        " biologie și biochimist folosește o metodă de vinificare uitată: vinurile nefiltrate îmbătrânite în butoaie de doi până la " + 
        " patru ani care permit o sedimentare naturală, lentă, de complexitate surprinzătoare." 
    },
]
