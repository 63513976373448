import React from 'react';
import './Footer.css';
import logo from "../../images/logo1_noBackground1.png"
import { Link} from 'react-router-dom';
//import { BsWhatsapp as WhatsApp} from "react-icons/bs";
import { BsTelephone as Phone } from "react-icons/bs";
import { AiOutlineMail as Email } from "react-icons/ai";


const Footer = () => {
    return (
        <footer className="footer" >
            <div className="footer_links">
            {/*<h1> Ce avem aici ? </h1>*/}
                <div className="footer_logo">
                        <img src ={logo} width="147" height="147" alt="logo">

                        </img>
                    </div>
                <ul>
                    <li className="footer_li">
                        <Link to="/About" className="footer_href"> Despre noi</Link>
                    </li>
                    
                    {/*<li className="footer_li">
                        <Link to="About.html" className="footer_href"> Customer service </Link>
                    </li>*/}
                    <li className="footer_li_last">
                        <Link to="/Terms" className="footer_href"> Termeni si conditii </Link>
                    </li>
                    {/*<li className="footer_li">
                        <Link to="About.html" className="footer_href"> Privacy policy </Link>
                    </li>*/}
                    {/*<li className="footer_li_last">
                        <Link to="/About" className="footer_href"> Contact </Link>
                    </li>*/}
                </ul>
               
            </div>
            <div className="contactData">
                    <Phone style = {{ color: "#a6a6a6"}}/>
                    <a href="tel:0749757201">0749757201</a>
                    
                    <Email style = {{ color: "#a6a6a6" }}/>
                    <a href="mailto:contact@privatereserve.eu" >contact@privatereserve.eu</a>
            </div>
        </footer>
    )
}

export default Footer;