export const ProductsInfo = [
     {
         id:"1",
         picture:'./images/LesGenevrieres2015.png',
         domain:'Domain de Montmain', 
         name:'Les Genevrieres 2009 Grand Tradition',
         region: 'Bourgogne Hautes Cotes de Nuits',
         price: 245, 
         aging_potential: "25 ani", 
         grape_variety: "Pinot Noir", 
         organic: 0
    },
    {
        id:"2",
        picture:'./images/LesGenevrieres2015.png',
        domain:'Domain de Montmain', 
        name:'Les Genevrieres 2005',
        region: 'Bourgogne Hautes Cotes de Nuits',
        price: 235,
        aging_potential: "25 ani", 
        grape_variety: "Pinot Noir", 
        organic: 0
    },
    {
        id:"3",
        picture:'./images/LeHautDeVillage.png',
        domain:'Domain de Montmain', 
        name:'Le Haut de Village  2014',
        region: 'Bourgogne Hautes Cotes de Nuits',
        price: 105,
        aging_potential: "10 ani", 
        grape_variety: "Pinot Noir", 
        organic: 0
    },
    {
        id:"4",
        picture:'./images/LeHautDeVillage.png',
        domain:'Domain de Montmain', 
        name:'Le Haut de Village 2017',
        region: 'Bourgogne Hautes Cotes de Nuits',
        price: 110,
        aging_potential: "10 ani", 
        grape_variety: "Pinot Noir", 
        organic: 0
    },
    {
        id:"5",
        picture:'./images/ClosDeChateauMontmain.png',
        domain:'Domain de Montmain', 
        name:'Clos de Chateau 2018',
        region: 'Bourgogne Hautes Cotes de Nuits',
        price: 132,
        aging_potential: "6 ani", 
        grape_variety: "Chardonnay", 
        organic: 0
    },
    {
        id:"6",
        picture:'./images/LeRouardMontmain.png',
        domain:'Domain de Montmain', 
        name:'Le Rouard 2014',
        region: 'Bourgogne Hautes Cotes de Nuits',
        price: 156,
        aging_potential: "10 ani", 
        grape_variety: "Chardonnay", 
        organic: 0
    },
    {
        id:"7",
        picture:'./images/LesJiromeesMontmain.png',
        domain:'Domain de Montmain', 
        name:'Le Jiromees 2014',
        region: 'Bourgogne Hautes Cotes de Nuits',
        price: 180,
        aging_potential: "15 ani", 
        grape_variety: "Chardonnay", 
        organic: 0
    },
    {
        id:"8",
        picture:'./images/LesJiromeesMontmain.png',
        domain:'Domain de Montmain', 
        name:'Le Jiromees 2014 Grand Tradition',
        region: 'Bourgogne Hautes Cotes de Nuits',
        price: 245,
        aging_potential: "15 ani", 
        grape_variety: "Chardonnay", 
        organic: 0
    },

    // Beaujolais
    {
        id:"9",
        picture:'./images/julienas_aufranc_les_cerisiers.png',
        domain:'Pascal Aufranc', 
        name:'Les Cerisiers  2019',
        region: 'AOC Julienas',
        price: 70,
        aging_potential: "5 ani", 
        grape_variety: "Gamay", 
        organic: 0
    },
    {
        id:"10",
        picture:'./images/JeanMichelDupreVignes1940.png',
        domain:'Jean-Michel Dupre', 
        name:'Vignes de 1940 2020',
        region: 'AOC Beaujolais Villages',
        price: 55,
        aging_potential: "5 ani",
        grape_variety: "Gamay", 
        organic: 0
    },
    {
        id:"11",
        picture:'./images/LucienLardyCoteDuPy.png',
        domain:'Lucien Lardy', 
        name:'Cote du Py 2020',
        region: 'AOC Morgon',
        price: 97,
        aging_potential: "15 ani", 
        grape_variety: "Gamay", 
        organic: 0
    },
    {
        id:"12",
        picture:'./images/RobertPerrouldFournaiseDuPerou.png',
        domain:'Robert Perroud', 
        name:'La Pente 2019',
        region: 'AOC Cote De Brouilly',
        price: 103,
        aging_potential: "15 ani", 
        grape_variety: "Gamay", 
        organic: 1
    },
    {
        id:"13",
        picture:'./images/RomainPerroud.png',
        domain:'Robert Perroud', 
        name:'Romain 2019',
        region: 'AOC Cote De Brouilly',
        price: 120,
        aging_potential: "15 ani", 
        grape_variety: "Gamay", 
        organic: 0
    },
    {
        id:"14",
        picture:'./images/ChirasBourgogneBlancPerroud.png',
        domain:'Robert Perroud', 
        name:'Chiras Vallee',
        region: 'AOC Bourgogne Blanc',
        price: 103,
        aging_potential: "15 ani", 
        grape_variety: "Chardonnay", 
        organic: 0
    },
    {
        id:"16",
        picture:'./images/RobertPerouldTerresBlanches.png',
        domain:'Robert Perroud', 
        name:'Terres Blanches 2019',
        region: 'AOC Beaujolais Villages Blanc',
        price: 69,
        aging_potential: "5 ani", 
        grape_variety: "Chardonnay", 
        organic: 0
    },
    {
        id:"15",
        picture:'./images/EmmanuelFellot-BourgogneBlank.png',
        domain:'Emmanuel Fellot', 
        name:'Chardonnay 2019',
        region: 'AOC Bourgogne Blanc',
        price: 78,
        aging_potential: "15 ani", 
        grape_variety: "Chardonnay", 
        organic: 0
    },
    {
        id:"17",
        picture:'./images/b300_beaujolais_villages_blanc_lardy.png',
        domain:'Lucien Lardy', 
        name:'Chardonnay 2019',
        region: 'AOC Beaujolais Villages Blanc',
        price: 65,
        aging_potential: "5 ani", 
        grape_variety: "Chardonnay", 
        organic: 0
    }
]