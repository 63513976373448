export default function validateInfo(values) {
    let errors = {};
  
    //console.log("values.firstName=" + values.firstName);
    if (!values.firstName.trim()) {
      errors.firstName = 'Username required';
    }
    //console.log("errors.firstName=" + errors.firstName);

    if(!values.lastName.trim()) {
      errors.lastName = 'Last name required';
    }

    if(!values.address.trim()) {
      errors.address = 'Address required';
    }

    if(!values.email.trim()) {
      errors.email = 'Email required';
    }

    if(!values.phone.trim()) {
      errors.phone = 'Phone required';
    }

    //console.log("values.agreeTermsAndConditions=" + values.agreeTermsAndConditions);
    if(values.agreeTermsAndConditions === 'false') {
      errors.agreeTermsAndConditions = 'Please agree';
    }

    // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    //   errors.name = 'Enter a valid name';
    // }
  
    // if (!values.email) {
    //   errors.email = 'Email required';
    // } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    //   errors.email = 'Email address is invalid';
    // }
    // if (!values.password) {
    //   errors.password = 'Password is required';
    // } else if (values.password.length < 6) {
    //   errors.password = 'Password needs to be 6 characters or more';
    // }
  
    // if (!values.password2) {
    //   errors.password2 = 'Password is required';
    // } else if (values.password2 !== values.password) {
    //   errors.password2 = 'Passwords do not match';
    // }
    return errors;
  }