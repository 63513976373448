import React  from "react";
import './CheckOut.css';
import {CartState} from '../Context/Context'
import { Button } from "../Button"

import validate from './validateInfo';
import validateForm from './validateForm';
import { useRef } from 'react';
import { Link} from 'react-router-dom';
import { ShippingContext } from "../Context/ShippingContext";
import { useContext } from "react";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

const CheckOut = props => {

    const {
        state: { cart }
      } = CartState();

      const { handleChange, handleSubmit, values, errors } = validateForm(
        validate
      );

      const orderReceived = props.orderReceived;

      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
      }

      const shippingPrice = useContext(ShippingContext);

      const numePrenumeDiv = useRef(null);
      const emailPhoneDiv = useRef(null);
      const addressDiv = useRef(null);
      const termsAndConditionsDiv = useRef(null);

    return (
        <div className="TabReview">
          
                <div className="CheckOut">
                    <h3>Date de facturare</h3>
                    <form className="CheckOutForm" method='POST' action={process.env.REACT_APP_DEPLOYMENT_DIR + '/checkout'}>
                        <div ref={numePrenumeDiv} className="CheckOutTwoFieldsOnALine">
                            <div className="CheckOutFirstFieldOnALine">
                                <label>Nume<span>*</span></label>
                                <input type="text" name="firstName" value={values.firstName} onChange={handleChange} />
                                {errors.firstName &&  <p> Va rugam sa introduceti numele</p>}
                            </div>
                            <div className="CheckOutSecondFieldOnALine">
                                <label>Prenume<span>*</span></label>
                                <input type="text" name="lastName" value={values.lastName} onChange={handleChange} />
                                {errors.lastName &&  <p> Va rugam sa introduceti prenumele</p>}
                            </div>
                        </div>
                        <div ref = {emailPhoneDiv} className="CheckOutTwoFieldsOnALine">
                            <div className="CheckOutFirstFieldOnALine">
                                <label>Email<span>*</span></label>
                                <input type="text" name="email" value={values.email} onChange={handleChange}/>
                                {errors.email &&  <p> Va rugam introduceti adresa de email</p>}
                            </div>
                            <div className="CheckOutSecondFieldOnALine">
                                <label>Telefon<span>*</span></label>
                                <input type="text" name="phone" value={values.phone} onChange={handleChange} />
                                {errors.phone &&  <p> Va rugam introduceti telefonul</p>}
                            </div>
                        </div>
                        <div ref = {addressDiv} className="CheckOutInputField">
                            <label>Adresa<span>*</span></label>
                            <input type="text" name="address" value={values.address} onChange={handleChange} />
                            {errors.address &&  <p> Va rugam introduceti adresa</p>}
                        </div>
                        <div ref = {termsAndConditionsDiv} className="CheckOutAgreeTermsAndConditions">
                            <input type="checkbox" name="agreeTermsAndConditions" value = {values.agreeTermsAndConditions} onChange={handleChange}/>
                            <label>Sunt de acord cu </label>  <Link to="/Terms" className="CheckOutTermsAndConditionsLink"> Termeni si Conditii </Link>
                            {errors.agreeTermsAndConditions &&  <p> Va rugam sa fiti de acord cu Termenii si conditiile</p>}
                        </div>

                        <div className="CheckOutInputFieldNoError">
                            <label>Oras</label>
                            <input type="text" name="city" value = {values.city} onChange={handleChange}/>
                        </div>
                        <div className="CheckOutInputFieldNoError">
                            <label>Cod postal </label>
                            <input type="text" name="postCode" value = {values.postCode} onChange={handleChange}/>
                        </div>
                        <div className="CheckOutInputFieldNoError">
                            <label>Tara </label>
                            <input type="text" name="countryName"/>
                        </div>
                        {/*<div className="CheckOutInputField">
                            <label>Company name </label>
                            <input type="text" name="name"/>
                        </div>*/}
                    </form>
                </div>
                <div className="ReviewOrder">
                    <h3>Comanda</h3>

                    <table className="ReviewOrderTable">
                            <tbody className="ReviewOrderTbody">
                            <tr className="OrderTableRow">
                                <td className="ProductName">Produs</td>
                                <td className="ProductTotal">Total </td>
                            </tr>

                             {cart.map( (prod) => (
                                <tr key = {prod.id} className="OrderTableRowBorder">
                                    <td className="ProductName">{prod.description}</td>
                                    <td className="ProductTotal">{prod.price*prod.qty} RON</td>
                                </tr>
                            ))}

                            <tr className="OrderTableRowBorder">
                                <td className="ProductName">Total</td>
                                <td className="ProductTotal">{cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0)} RON</td>
                            </tr>

                            <tr className="OrderTableRowBorder">
                                <td className="ProductName">Livrare</td>
                                <td className="ProductTotal">{cart.length > 0 ? shippingPrice : 0} RON</td>
                            </tr>

                            <tr className="OrderTableRowBorder">
                                <td className="OrderTOTAL">ORDER TOTAL</td>
                                <td className="ProductTotal">{cart.reduce((acc, curr) => 
                                acc + Number(curr.price) * curr.qty, 0) + (cart.length > 0 ? shippingPrice : 0)} RON</td>
                            </tr>

                        </tbody>

                    </table>
                    <div className="CheckOutButton">
                        <Button  onClick={()=>{

                            handleSubmit();    

                            if(values.firstName === "" || values.lastName === "") {
                                numePrenumeDiv.current.className = "CheckOutTwoFieldsOnALine" + " BiggerHeight";
                            }

                            if(values.firstName !== "" && values.lastName !== "") {
                                numePrenumeDiv.current.className = "CheckOutTwoFieldsOnALine";
                            }


                            if(values.email === "" || values.phone === "") {
                                emailPhoneDiv.current.className = "CheckOutTwoFieldsOnALine" + " BiggerHeight";
                            }

                            if(values.email !== "" && values.phone !== "") {
                                emailPhoneDiv.current.className = "CheckOutTwoFieldsOnALine"; 
                            }

                            if(values.address === "") {
                                addressDiv.current.className = "CheckOutInputField" + " AddressBiggerHeight";
                            }
                            
                            if(values.address !== "") {
                                addressDiv.current.className = "CheckOutInputField";
                            }

                            if(values.agreeTermsAndConditions === 'false') 
                            {
                                termsAndConditionsDiv.current.className = "CheckOutAgreeTermsAndConditions" +  " TermsAndConditionsBiggerHeight";
                            } else {
                                termsAndConditionsDiv.current.className = "CheckOutAgreeTermsAndConditions";
                            }

                            //console.log("values.agreeTermsAndConditions=" + values.agreeTermsAndConditions);
                            //console.log("errors.agreeTermsAndConditions=" + errors.agreeTermsAndConditions);
                            if(values.firstName === "" || values.lastName === "" || 
                                values.address === "" || values.email === "" || values.phone === "" || values.agreeTermsAndConditions === 'false') 
                            {
                                  return;      
                            }

                            var formData = new FormData();
                            formData.append('firstName', values.firstName);
                            formData.append('lastName', values.lastName);
                            formData.append('address', values.address);
                            formData.append('email', values.email);
                            formData.append('phone', values.phone);

                            const dataAdaugariiStr = new Date().toLocaleString('ro-RO',  
                            { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', 
                            hour: 'numeric', minute:'numeric' });
                            formData.append('dataAdaugarii', dataAdaugariiStr);

                            const orderNumberStr = getRandomInt(1000, 9999).toString();
                            formData.append('orderNumber', orderNumberStr);

                            formData.append('city', values.city);
                            formData.append('county', values.city);
                            formData.append('postalCode', values.postCode);

                            formData.append('itemsCount', cart.length);
                            
                            let total = cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0);
                            formData.append('subtotal', total);
                            formData.append('shipping', shippingPrice);
                            formData.append('total', total + shippingPrice);
                            
                            formData.append('bccaddress', process.env.REACT_APP_BCC_ADDRESS);
                            console.log("bccaddress="+  process.env.REACT_APP_BCC_ADDRESS);

                            let items=[];
                            cart.map( (prod) => ( 
                                items.push(
                                        {   winename:prod.domain + " - " + prod.description, 
                                            image: process.env.REACT_APP_DEPLOYMENT_DIR + "/" + prod.img,
                                            price:prod.price, 
                                            quantity:prod.qty, 
                                            winetotal:prod.price*prod.qty
                                        }
                                )
                            ))
                            formData.append("items", JSON.stringify(items));                                        
                            //console.log("items=" + JSON.stringify(items));

                            const requestOptions = {
                                method: 'POST',
                                body: formData
                            };

                            //
                            // call made in node js express server
                            // headers: { 
                            //     'Accept': 'application/json',
                            //     'Content-Type': 'application/json' 
                            // },
                            // body: JSON.stringify({ title: 'React Hooks POST Request Example' })
                            //


                            fetch('/checkout.php', requestOptions)
                                .then(response => { 
                                    //console.log("response available=" + response.status);
                                    var total = cart.reduce((acc, curr) => 
                                        acc + Number(curr.price) * curr.qty, 0) + (cart.length > 0 ? shippingPrice : 0);
                                    ReactPixel.track('Purchase', {currency:'RON', value:total} );

                                    ReactGA.event({action:'Purchase',value:total });

                                    orderReceived(orderNumberStr, dataAdaugariiStr);
                                })
                                .catch(error => {console.log("Error received")});

                            }}> Trimite </Button>
                    </div>
                </div>


        </div>
    )
}

export default CheckOut;