import './AboutUs.css'
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';



const AboutUs = () => {
        ReactPixel.track("PageView", 'DespreNoi');
        ReactGA.send("pageview");

        return ( 
            <div className = "AboutUs" >
                <div className="AboutUs_Image">
                    <img className = "AboutUs_Image2" src='./images/Belcier.png' alt="AboutUSChateau"/>
                </div>
                <div className = "AboutUS_Text">
                    <h1>Private Reserve - Wines from Old World</h1>
                    <span>Private Reserve, importator de vinuri si distribuitor, ne bucuram sa va oferim o selectie prestigioasa de vinuri de exceptie din podgoriile din Franta. </span>
                    <span>Private Reserve lucreaza cu artizani care sunt determinati sa produca vinuri ca expresie a terroir-ului local si a traditiilor specifice regiunii.
                    Puteti sa ne considerati magazinul de vinuri din vecinatatea dumneavoastra care va poate incanta cu o sticla delicioasa de vin din cele mai renumite podgorii si regiuni din Franta.   </span>
                    <span> Scopul nostru nu este doar sa distribuim vinuri, dorim sa cream o conexiune intre producator si consumatorul vinului. </span>
                    <span>Aducem vinuri din pivnita celor mai distinsi producatori: Chateau de Villars Fontaine in Burgundia si producatori din regiunea Beaujolais. </span>
                </div>
                <div className = "VillarsFontaine">
                    <h1>Chateau de Villars Fontaine in Burgundia</h1>
                    <div className = "VillarsFontaine_Flex">
                        <div className = "VillarsFontaine_Text">
                                <span>
                                    Proprietatea a fost creata de renumitul oenologist si profesor Bernard Hudelot in 1970, pe locul unor podgorii exploatata de 
                                    Canon-ul de Saint Denis si ducii de Burgundia  intre secolele 11 si 17.
                                    <br/>Regiunea Cotes de Nuits si Hautes Cotes de Nuits sunt centrul podgoriilor clasificate Grand Crus.
                                </span>
                                <span><br/>Din 1996, Pinot Noir-ul cultivat in cadrul aceste proprietati devine din ce in ce mai puternic, elegant si complex.</span>
                                <span> Chardonnay ajunge la maturitatea ideala multumita argilei albe din sol, rezultand vinuri albe apropriate de cele din podgoria Corton-Charlemagne acum 30 de ani, 
                                    unul din cele mai celebrate vinuri albe din regiunea Burgundia.
                                </span>
                                <span>
                                    <br/>In 1984, odata cu cresterea podgoriei, Bernard Hudelot se decide sa-i dea numele Domain de Montmain. Mai tarziu, odata cu achizitia castelului in anul 2000,  
                                    proprietatea stra-bunicii, proprietate se va numi Chateau de Villars Fontaine. 
                                </span>
                            </div>
                            <div className="VillarsFontaine_Image">
                                <img className = "VillarsFontaine_Image2" src='./images/VillarsFontaine.jpg' alt="AboutUSChateau"/>
                            </div>
                    </div>
                    <div>
                      <span>
                          Procesul de vinificare urmeaza indeaproape metodologia traditionala specifica locului, fara adaos de enzime, tanini artificiali, etc. <br/>
                      </span>
                        Bernard Hudelot, o personalitate marcanta a regiunii Burgundia, Oenolog, Artizan, Profesor de Biologie si Biochimist foloseste o metoda uita:
                        vinul nefiltrat este invechit in butoaie de stejar pentru o perioda de la 2 ani la 4 ani, lucru care permit o sedimentare naturala, inceata, rezultand vinuri complexe. 
                        Vinurile au o capacitate de a se invechi de la 10 la 30 de ani.
                      <span> 
                      </span>
                    </div>
                </div>
                <div className = "Beaujolais">
                    <h1>Beaujolai Villages</h1>
                    <div className = "Beaujolais_Flex">
                        <div className="Beaujolais_Image">
                            <img  src='./images/Beaujolais.png' alt="Beaujolais"/>
                        </div>
                        <div className="Beaujolais_Text">
                            <p>
                                Producatorii din regiunea Beaujolais au in comun: 
                            </p>
                            <p>
                                Meritul de a se concentra pe calitate, limitand productia la pamantul pe care il cultiva, oferind in exclusivitate vin provenit din strugurii produsi de catre producatorul vinului pe parcelele proprii.
                            </p>
                            <p>
                                Efortul si munca asidua in cultivarea parcelelor si in procesul de vinificare, contribuind la fiecare pas de la muncile agricole pana la imbuteliere.
                            </p>

                            <p>
                                Dorinta de a cultiva parcelele intr-un mod sustenabil pentru a respecta mediul si biodiversitatea din  Sudul Burgundiei: GAMAY NOIR si CHARDONNAY.
                            </p>
                            <p>
                            Cerinta de a elabora vinuri deosebite si complexe multumita unei etici a muncii si rabdare in procesul de invechire a vinului.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        )
};


export default AboutUs;
