import React from 'react';
import './Products.css';
//import { useState } from 'react';

import Product from './Product';
import {ProductsInfo} from './ProductsInfo';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

//import AreYouOver18Diag from '../Dialogs/AreYouOver18Diag';


export default function Products() {
  
  //const [areYouOver18Dialog, setAreYouOver18Dialog] = useState(true);
  ReactPixel.pageView();

  //ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.send("pageview");

  return <div className="products"  >
      {/*<h1 className='products_title'>PRODUCTS DE BOURGOGNE</h1>*/}
      <div className="products_list">
        {ProductsInfo.map((item, index) => {
          return ( 
              <Product key = {item.id} id={item.id} domain={item.domain} 
              description={item.name} region={item.region} price={item.price} 
              img={item.picture} aging_potential={item.aging_potential}
              grape_variety={item.grape_variety} organic={item.organic}/>
          )
        })}
      </div>
      {/*{areYouOver18Dialog && <AreYouOver18Diag setOpenDiag={setAreYouOver18Dialog}/> }*/}
    </div>
};