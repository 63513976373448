import React, { useRef, useState } from 'react';
import './CosulMeuOverall.css';
import CosulMeu from './CosulMeu';
import CheckOut from './CheckOut';
import OrderComplete from './OrderComplete'
import { CartState } from "../Context/Context";
import ReactPixel from 'react-facebook-pixel';

/* eslint-disable jsx-a11y/anchor-is-valid */


const CosulMeuOverall = () => {

    const {
        state: { cart }, dispatch
      } = CartState();

    function handleClick (index)  {
        if(orderReceivedByServer === true) {
            console.log('Order received by server');
            if(index === 2) {
                return;
            }
        }
        console.log('handleClick index= ' + index.toString());
        if(index === 1) {
            //console.log('Setting count to 1' + index.toString());
            shoppingCart.current.className =   "CosulMeuActiveLink";
            checkOut.current.className =  "CosulMeuInactiveLink";
            orderComplete.current.className =  "CosulMeuInactiveLink";
            setCount(1);
        }

        var cartTotal = cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0);
        if(cartTotal === 0) {
            return;
        }

        if(index === 2) {
            shoppingCart.current.className =  "CosulMeuInactiveLink";
            checkOut.current.className =  "CosulMeuActiveLink";
            orderComplete.current.className =  "CosulMeuInactiveLink";
            setCount(2);
        }
        
        // if(index == 3) {
        //     shoppingCart.current.className =  "CosulMeuInactiveLink";
        //     checkOut.current.className =  "CosulMeuInactiveLink";
        //     orderComplete.current.className =  "CosulMeuActiveLink";
        //     setCount(3);
        // }
        
    }

    function orderReceived(orderNumber, date) {

//        console.log("Order received!!");
//        console.trace("date=" + date);
        setOrderNumber(orderNumber);
        setDate(date);
        var array = JSON.parse(JSON.stringify(cart));
        //console.log("array=" + JSON.stringify(array));
        setCartCopy(array);

        shoppingCart.current.className =  "CosulMeuInactiveLink";
        checkOut.current.className =  "CosulMeuInactiveLink";
        orderComplete.current.className =  "CosulMeuActiveLink";        
        setCount(3);

        setOrderReceivedByServer(true);

        //
        //  Empty the cart
        //
        cart.map( (prod) => (
        dispatch({
            type: "REMOVE_FROM_CART",
            payload: {id: prod.id,}} )
        ));    
        //console.log("cartCopy=" + JSON.stringify(cartCopy));
    }

    const shoppingCart = useRef(null);
    const checkOut = useRef(null);
    const orderComplete = useRef(null);
   
    const [count, setCount] = useState(1);
    const [orderReceivedByServer, setOrderReceivedByServer] = useState(false);
    const [orderNumber, setOrderNumber] = useState("");
    const [date, setDate] = useState("");

    const [cartCopy, setCartCopy] = useState([]);

//    console.log("REACT_APP_DEPLOYMENT_DIR=" + process.env.REACT_APP_DEPLOYMENT_DIR);
//    console.log("REACT_APP_BCC_ADDRESS=" + process.env.REACT_APP_BCC_ADDRESS);


    //render() {
    //ReactPixel.track("PageView", 'CosulMeu');

    return (
        <div className="CosulMeu">
            <div className="CosulMeuSelector">
                <ul>
                    <li ref={shoppingCart} className="CosulMeuInactiveLink">
                        <a  href=""   onClick = {(e) =>{ e.preventDefault(); handleClick(1)}}>
                            <img src="./images/icon_bag.png" alt=""></img>
                            SHOPPING CART
                        </a>
                    </li>
                    <li ref={checkOut} className="CosulMeuInactiveLink">
                        <a href="" onClick = {(e) => { e.preventDefault(); handleClick(2)}}>
                            <img src="./images/icon_ribbon.png" alt=""></img>
                            CHECK OUT
                        </a>
                    </li>
                    <li ref={orderComplete} className="CosulMeuInactiveLink">
                        <a href="" onClick = {(e) => { e.preventDefault(); handleClick(3)}}>
                            <img src="./images/arrow_carrot-down_alt.png" alt=""></img>
                            ORDER COMPLETE
                        </a>
                    </li>
                </ul>   
            </div>
            <div className="OrderPartSelector">
                {count === 1 && <CosulMeu changeActiveForm={handleClick}/>}
                {count === 2 && <CheckOut orderReceived={orderReceived}/>}
                {count === 3 && <OrderComplete orderNumber={orderNumber} date={date} cartCopy={cartCopy}/>}
            </div>
        </div>    
    )
    //}
}


export default CosulMeuOverall;