export const MenuItems = [
    // {
    //     title:'HOME', 
    //     url:'/Home',
    //     cName: 'nav-links'
    // },
    {
        id : 1,
        title:'Vinuri', 
        url:'/Vinuri',
        cName: 'nav-links'
    },
    {
        id : '2',
        title:'Artizani', 
        url:'/Artizani',
        cName: 'nav-links'
    },
    {
        id : '3',
        title:'Despre Noi', 
        url:'/About',
        cName: 'nav-links'
    },
    {
        id : '4',
        title:'COSUL MEU', 
        url:'/CosulMeu',
        cName: 'nav-links'
    },
]