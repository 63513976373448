import React from 'react';
import './Producer.css';

const Producer =  props => {
    return (
        <div className="producer">
                
                <div className="producer_name">
                    <h1> {props.name}</h1>
                    <p>{props.info}</p>
                </div>
                <img  src={props.picture} alt={props.name}/>
            
        </div>
    )
}

export default Producer;