import React from "react";
import "./AreYouOver18Diag.css";
import logo from "../../images/logo1_noBackground1.png";
import {Button} from '../Button';

function AreYouOver18Diag({ setOpenDiag }) {

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="title">
            <img src ={logo} width="147" height="147" alt="logo">
            </img>
            <p>Aveti peste 18 ani?</p>
        </div>
        <div className="modalFooter">
          <Button
            onClick={() => {
                setOpenDiag(false);
            }}
            id="cancelBtn"
          >
            Da
          </Button>
          <Button onClick={ ()=>{
                setOpenDiag(false);
                window.location.replace('http://www.google.com');
          }
          }>Nu</Button>
        </div>
      </div>
    </div>
  );
}

export default AreYouOver18Diag;