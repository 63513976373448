import React from 'react';
import Producer from './Producer';
import './Producers.css';

import {ProducersInfo} from './ProducersInfo';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';


const Producers = () => {
    ReactPixel.track("PageView", 'Artizani');
    ReactGA.send("pageview");

    return (
        <div className="producers_container">
                
                <h1>Producatori din regiunea Bourgogne si Beaujolais</h1>
                <p> Dorinta noastra este de a face legatura dintre consumatori si producatorii individuali din regiunea Bourgogne si Beaujolais. 
                Toti producatorii selectati au un respect deosebit pentru biodiversitate si mediu, limitandu-si productia la terenul pe care acestia 
                il cultiva, oferind  vinuri de calitate. 
                Cu pasiune si respect pentru natura, ei contribuie la fiecare pas in procesul de vinificare, de la cultivarea vitei de vie pana la imbuteliere. 
                </p>
               <div className="producers">
                {ProducersInfo.map((item, index) => {
                    return (
                        <Producer key={item.id} picture={item.picture} info={item.info} name={item.name}/> 
                    )
                
                })}
                </div>

            
        </div>
    )
}

export default Producers;


