import { useState } from 'react';

const  ValidateForm = (validate) => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    address: '',
    email: '', 
    phone: '', 
    city:'',
    postCode:'',
    agreeTermsAndConditions:'false'
  });
  const [errors, setErrors] = useState({    
    firstName: '',
    lastName: '',
    address: '',
    email: '', 
    phone: '' , 
    agreeTermsAndConditions:''
});
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = e => {

    const { name, value } = e.target;

    if(name==='agreeTermsAndConditions') {
      setValues({...values, 'agreeTermsAndConditions':e.target.checked});
      return;
    }

    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSubmit =()  => {
    //e.preventDefault();

    var errors = validate(values);
    setErrors(errors);
    setIsSubmitting(true);
  };

  /*
  useEffect(
    () => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
        callback();
      }
    },
    [errors]
  );
*/
  return { handleChange, handleSubmit, values, errors };
};

export default ValidateForm;