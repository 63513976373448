import './Terms.css'






const Terms = () => {
    return ( 
        <div className = "Terms" >
                
            <h1>Termeni si Conditii Generale de Vanzare 
            </h1>

            <h1>
            Magazin Online Private Reserve 
            </h1>

            <p> 
Inainte de a utiliza sau obtine orice materiale, informatii, servicii prin intermediul acestui magazin online, va rugam sa cititi cu atentie 
Termenii si Conditiile. Aceste prevederi se completeaza si cu legea 365/2002 privind comertul electronic republicata si modificata si cu  
OUG 34/2014 privind drepturile consumatorilor in cadrul contractelor incheiate cu profesionistii.
            </p>

            <h2>
1. Informatii administrator magazin online
            </h2>
            <p> 
Site-ul www.privatereserve.eu este administrat de catre Private Reserve SRL cu sediul in localitatea Dimieni, inregistrata la Oficiul Registrului Comertului 
sub nr. J23/4919/2019, Cod de Identificare Fiscala : RO41859326 Tel. 0799723723, email contact@privatereserve.eu. 
            </p>

            <h2>
2. Acceptare termeni si conditii
            </h2>
            <p>
Administratorul va ofera accesul in magazinul online si utilizarea acestuia, sub rezerva acceptarii de catre dumneavoastra a acestor termeni si conditii. 
Prin accesarea, utilizarea, obtinerea sau achizitionarea oricarui produs, continut, date, materiale, informatii sau servicii prin intermediul  
magazinului nostru online, dumneavoastra sunteti de acord sa respectati acesti Termeni si Conditii. Administratorul isi rezerva dreptul de a  
completa sau modifica orice informatie din magazinul virtual fara a anunta in prealabil utilizatorii site-ului.
            </p>

            <h2>
3. Drepturi de autor
            </h2>
            <p>
Vizitatorul/Cumparatorul intelege dreptul de proprietate intelectuala si nu va descarca/utiliza continutul magazinului online Private Reserve, imagini, 
descrieri, elemente grafice, fara acordul scris al administratorului intrucat reprezinta proprietatea administratorului, fiind aparat de legea dreptului 
de autor si de legile referitoare la proprietatea intelectuala si industriala.
            </p>

            <h2>
4. Perfectarea contractului la distanta, valabilitate
            </h2>
            <p>
- Odata cu lansarea comenzii de pe magazinul online, cumparatorul accepta comunicarea prin e-mail sau telefon prin care administratorul isi deruleaza activitatea. <br/>
- Prezentul contract la distanta intra in vigoare in momentul confirmarii comenzii de catre administrator. Confirmarea se efectueaza electronic.<br/>
- Administratorul nu considera sub nici o forma o comanda neconfirmata ca avand valoarea unui Contract.<br/>
- Cumparatorul devine proprietarul marfii dupa confirmarea platii, in cazul platii prin OP sau card respectiv dupa efectuarea platii si semnarea documentelor de transport in cazul livrarii prin curier cu plata ramburs.
            </p>

            <h2>
5. Preturi, modalitati de plata, facturare
            </h2>

            <p>
- Preturile sunt afisate pe pagina dedicata fiecarui produs, contin TVA si nu includ cheltuielile de livrare decat daca este specificat in mod expres acest aspect
sau se ofera ca si bonus pentru comenzile mari. In cazul in care pretul nu este afisat, veti avea posibilitatea de a cere o oferta iar un operator va va 
comunica pretul in cel mai scurt timp posibil.<br/>
- Pretul produsului comandat este cel afisat la data plasarii comenzii. <br/>
- Pretul imprimat pe factura este acelasi cu cel transmis de operatori in momentul confirmarii comenzii. Modalitatile de plata sunt disponibile pe pagina de lansare a comenzii.<br/>
- Pe baza informatiilor furnizate  de cumparator va fi emisa o factura pentru bunurile livrate. 
            </p>

            <h2>
6. Stocuri
            </h2>

            <p>
Produsele sunt disponibile in stoc. Cu toate acestea, administratorul nu poate garanta disponibilitatea produselor postate. Clientii sunt informati corect, 
conform termenilor si conditiilor, de disponibilitatea produselor comandate inainte de confirmarea livrarii. Rareori, pot aparea erori de comunicare in acest 
sens.
            </p>
<h2>
7. Garantii
</h2>
<p>
SC. Private Reserve SRL. garanteaza ca produsele livrate sunt originale, sigilate (în forma în care provin de la parteneri) si însotite de factura fiscala, chitanta ( eliberata de administrator sau firma de curierat).
Produselor comercializate le este garantata calitatea în conditiile respectarii prescriptiilor de depozitare si transport.
Pentru orice reclamatie sau sesizare va rugam sa ne contactati la adresa de email contact@privatereserve.eu            
</p>

<h2>
8. Informatii privind exercitarea dreptului de retragere
</h2>
<p>
Aceste prevederi se completeaza cu legea 365/2002 privind comertul electronic republicata si modificata si cu OUG 34/2014 privind drepturile consumatorilor in 
cadrul contractelor incheiate cu profesionistii.
</p>

<h2>
Politica de retur
</h2>

<p>
Conform OUG 34/2014 aveti dreptul de a va retrage din prezentul contract, fara a preciza motivele si fara a suporta penalitati în termen de 14 zile. Perioada de retragere expira dupa 14 zile începând de la ziua la care intrati dumneavoastra sau o parte terta, alta decât transportatorul, indicata de dumneavoastra, în posesia fizica a produselor.
</p>


<h2>
Consecintele retragerii
</h2>

<p>
Daca va retrageti, vom rambursa orice suma pe care am primit-o de la dumneavoastra, inclusiv costurile livrarii, cu exceptia costurilor suplimentare determinate de faptul ca ati ales alta modalitate de livrare decât cel mai ieftin tip de livrare standard oferit de noi, fara întârzieri nejustificate si, în orice caz, nu mai târziu de 14 zile de la data la care suntem informati cu privire la decizia dumneavoastra de a va retrage din prezentul contract. 
</p>

<p>
Conditiile dreptului de retragere se aplica doar consumatorilor persoane fizice, definite in art 2, pct.1 al OUG 34/2014.
Va trebui sa suportati costul direct al returnarii produselor. Consumatorul este responsabil de integritatea produsului pana la intrarea in posesia magazinului. 
Sunteti responsabil pentru diminuarea valorii produselor care rezulta din manipulari, altele decât cele necesare pentru determinarea naturii, calitatilor si functionarii produselor.
</p>


<p>
Daca va gânditi sa returnati produsul, nu îl utilizati! Nu puteti utiliza produsele pe care le-ati primit, înainte de a lua o decizie cu privire la anularea contractului de vânzare-cumparare. Dreptul de retragere exista pentru a va permite sa examinati produsul asa cum ati face-o într-un magazin, nu pentru a va oferi 14 zile de utilizare gratuita.
</p>

<h2>
9. Program de lucru, livrari, termene
</h2>
<p>
- O comanda poate fi plasata in orice moment dar perioada de procesare este de Luni pana Vineri in intervalul orar 8-16, exceptand sarbatorile legale. <br/>
- Termenul de livrare pentru produse este de 48-72 ore din momentul confirmarii livrarii.<br/>
- Livrarile fizice se efectueaza door to door, prin curierat rapid. Pot exista intarzieri fata de termenul de livrare stabilit in momentul confirmarii comenzii; in acest caz administratorul nu isi asuma nici o raspundere in numele firmelor de curierat.<br/>
- Administratorul isi asuma responsabilitatea in privinta livrarii produselor comandate de cumparator. 
</p>

<h2>
10. Obligatiile cumparatorului
</h2>

<p>
- Sa furnizeze informatii reale, corecte si actuale despre entitatea sa atunci cand acestea i se cer, in caz contrar exista posibilitatea refuzarii comenzilor.<br/>
- Sa accepte solutionarea eventualelor probleme in termen de 30 de zile de la data sesizarii acestora printr-un email adresat  administratorului.<br/>
- Sa accepte TERMENII SI CONDITIILE administratorului odata cu lansarea comenzilor si sa achite contravaloarea marfii prin metodele puse la dispozitie, la momentul transmiterii comenzii.
</p>

<h2>
11. Obligatiile vanzatorului
</h2>

<p>
- Sa puna la dispozitia utilizatorilor gratuit informatii profesionale cu privire la produsele si serviciile sale. Continutul publicat in  magazinul virtual
 are caracter orientativ. Caracteristicile produselor si documentatia tehnica aferenta acestora afisate pe site sunt puse la dispozitie de catre producatori si furnizori.
Imaginile produselor sunt afisate cu titlu de prezentare. In functie de lotul in care au fost fabricate, aspectul real al produsului poate diferi.<br/>
- Sa contacteze clientii pentru confirmarea comenzilor, prin e-mail sau telefonic.<br/>
- Sa livreze produsele in cel mult 30 zile de la confirmarea livrarii, daca acestea sunt in stoc.<br/>
- Sa utilizeze datele personale colectate de la clienti/utilizatori in concordanta cu sectiunea `Confidentialitate`, parte integranta  din TERMENI SI CONDITII. <br/>
</p>

<h2>
12. GDPR Politica de confidentialitate 
</h2>
 
<h2>
Ce categorii de date prelucram?
</h2>
<p>
Colectăm in general datele dvs. cu caracter personal direct de la dumneavoastra, astfel incat aveti controlul asupra tipului de informaie pe care ne-o oferiti. 
Datele ce vor fi salvate in baza de date Private Reserve sunt: nume, prenume, adresa de email, numar de telefon, adresa si alte date pentru facturare numai daca acestea 
sunt oferite în mod voluntar. Refuzul furnizarii acestora determina imposibilitatea procesarii comenzilor transmise online catre magazinul virtual Private Reserve.
</p>

<h2>
Scopul prelucrarii 
</h2>
<p>
Scopul colectarii datelor este: prestarea serviciilor in beneficiul d-voastra (incheierea contractelor, facturarea produselor achizitionate, onorarea comenzilor, 
solutionarea oricaror probleme privind produsele achizitionate) 
</p>
<h2>
Cât timp păstrăm datele dvs. cu caracter personal
</h2>
 
<p>
Ca regulă generală, vom stoca datele dvs. cu caracter personal atat timp cat este necesar pentru a atinge scopul pentru care au fost furnizate sau cat este cerut prin legile 
aplicabile.  Puteți să ne solicitați oricând ștergerea anumitor informații sau închiderea contului și vom da curs acestor solicitări, sub rezerva păstrării anumitor informații 
inclusiv ulterior închiderii contului, în situațiile în care legislația aplicabilă sau interesele noastre legitime o impun.
</p>    

<h2>
Drepturile de care beneficiati
</h2>

<p>Conform GDPR si Legii nr. 677/2001, beneficiati de dreptul de acces, de interventie gratuita asupra datelor, dreptul de a nu fi supus unei decizii individuale si dreptul de a 
va adresa justitiei. Totodata, aveti dreptul sa va opuneti prelucrarii datelor personale care va privesc si sa solicitati stergerea datelor. Pentru exercitarea acestor drepturi, 
va puteti adresa cu o cerere online la adresa contact@privatereserve.eu. De asemenea, va este recunoscut dreptul de a va adresa justitiei. Datele dumneavoastra nu vor fi transferate în strainatate.
</p>
<p>
Daca unele din datele despre dumneavoastra sunt incorecte sau doriti sa le modificati, va rugam sa ne informati cât mai curând posibil.
</p>

        </div>
    )
};


export default Terms;
