import React  from "react";
import './CosulMeu.css';
import { CartState } from "../Context/Context";
import { useEffect, useState } from "react";

import { Button } from "../Button"
import { ShippingContext } from "../Context/ShippingContext";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';


const CosulMeu = props => {

    const changeActiveForm = props.changeActiveForm;
    function handleClick (index)  {
        //console.log('Click happened ' + index.toString());
        if(total > 0) {
            ReactPixel.track('InitiateCheckout', {value:total});
            ReactGA.event({category:'InitiateCheckout', action:'InitiateCheckout',value:total });

            changeActiveForm(2);
        }
    }

    const {
        state: { cart },
        dispatch,
      } = CartState();

      const [total, setTotal] = useState();

      useEffect(() => {
          var cartTotal = cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0);
        setTotal( cartTotal );
      }, [cart]);

      const shippingPrice = useContext(ShippingContext);
      const {params} = useParams();
      //console.trace("ID=" + params);
      console.trace("ANA are mere " + window.location.search);

    //   for (var i = 0; i < cart.length; i++) 
    //   { 
    //       console.log(cart[i]); 
    //   }

    return (
        <div className="CosulMeu">
            <div className="Cart">
                <table className="CartTable">
                    <thead className="CartTableTHead">
                        <tr>
                            <th className="CartTableHeader">Image</th>
                            <th className="CartTableHeader">Product Name</th>
                            <th className="CartTableHeader">Price</th>
                            <th className="CartTableHeader">Quantity</th>
                            <th className="CartTableHeader">Total</th>
                            <th className="CartTableXHeader">×</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cart.map( (prod) => (
                             <tr key={prod.id}>
                             <td className="CartTableImageCell">
                                 <img src={prod.img} alt="bottle" />
                             </td>
                             <td className="CartTableProductNameCell">{prod.description}</td>
                             <td className="CartTablePriceCell">{prod.price} RON</td>
                             <td className="CartTableQuantityCell">
                                <ul>
                                    <li>
                                        <i className='fa fa-minus-circle'onClick={() => {
                                            //console.trace("Quantity changed for " + prod.description);
                                            dispatch({
                                                type: "CHANGE_CART_QTY",
                                                payload: {
                                                  id: prod.id,
                                                  qty: prod.qty - 1,
                                                }
                                              })
                                        }}/>       
                                    </li>
                                    <li>
                                        {prod.qty}
                                    </li>
                                    <li>
                                        <i className='fa fa-plus-circle'  onClick={() => {
                                        //console.trace("Increase " + prod.description + " id=" + prod.id);

                                            dispatch({
                                                type: "CHANGE_CART_QTY",
                                                payload: {
                                                  id: prod.id,
                                                  qty: prod.qty + 1,
                                                }
                                              })
                                        }}/>    
                                    </li>
                                </ul>
                            </td>
                            <td className="CartTableTotalPriceCell">{prod.qty*prod.price}</td>
                            <td className="CartTableDeleteCell">
                                <p onClick={()=>{
                                    //console.trace("Removing " + prod.description + " id=" + {prod});
                                dispatch({
                                    type: "REMOVE_FROM_CART",
                                    payload: {id: prod.id,}                                  
                                })}} >×</p></td>
                             </tr>
                          ))}

                    </tbody>
                </table>
            </div>

        

            <div className="TotalCart">
                <div className="CartOnLeft">
                </div>
                <div className="CartEnd">
                    <h2>Cart Total</h2>
                    <div className="WrapCart">
                        <div className="Subtotal">
                            <span>SUB TOTAL</span>
                            <p>{total} RON</p>
                        </div>
                        <div className="Shipping">
                            <span>Livrare</span>
                            <p>{cart.length > 0 ? shippingPrice : 0} RON</p>
                        </div>
                        <div className="Total">
                            <span>TOTAL</span>
                            <p>{total + (cart.length > 0 ? shippingPrice : 0)}  RON</p>
                        </div>
                    </div>
                    <div className="CheckOutButtonCosulMeu">
                        <Button buttonStyle = {total === 0 && "disabled"} onClick = {() => handleClick(2)}> CHECK OUT</Button>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default CosulMeu;