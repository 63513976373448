import React from 'react'
import './Button.css'

// const STYLES = [
//     'btn--primary',
//     'btn--outline'
// ]

// const SIZES = [
//     'btn--medium', 
//     'btn--large'
// ]

export const Button=({
    children, 
    type, 
    onClick, 
    buttonStyle, 
    buttonSize
}) => {
    //const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]

    //const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    //button className={'btn ${checkButtonStyle} {checkButtonSize}'} onClick={onClick} 
    //const checkButtonStyle = buttonStyle ;
    //console.trace("Button style = " + buttonStyle );
    //const buttonClassName = 'btn ' +  buttonStyle;
    //console.trace("Button class name = " + buttonClassName );

    return (
        <button className={'btn ' + buttonStyle} onClick={onClick}>
            {children}
        </button>
    )
}