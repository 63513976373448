import './Product.css'
import { Button } from "../Button"
import { CartState } from "../Context/Context";
import { GiCellarBarrels as Barrel} from "react-icons/gi";
import { GiGrapes as Grapes} from "react-icons/gi";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';


const Product = props => {
       const {
           state: {cart},
           dispatch
         } = CartState();


     function handleClick (product)  {
//        console.trace("Cumpara=" + product);  
         
        const existingProduct = cart.find((c) => c.id === product.id);
        if(existingProduct != null)
        {
//            console.trace("Existing product=" + existingProduct.id);   
            dispatch({
                type:"CHANGE_CART_QTY", 
                payload: {
                    id: existingProduct.id,
                    qty: existingProduct.qty + 1,
                  }
               });

        }
        else {
           dispatch({
                type: "ADD_TO_CART",
                payload: product,
           });
           ReactPixel.track('AddToCart', {content_name: product.description});
           ReactGA.event({category:'AddToCart', action:'AddToCart',  label:product.description });

   
        }

         //console.trace(cart);  
    }

    
    const organic= props.organic;
    return (
        <div className="product">
            <div className="product_image_info">
                <div className="ProductImage">
                    <img src={props.img} alt="bottle" />
                </div>
                <div className="product_info">
                    <p className="product_title">{props.domain}</p>
                    <p className="product_description"> {props.description}</p>
                    <p className="product_region">{props.region}</p>
                    <div className="product_params">
                        <div className="aging_potential_icon">
                            <Barrel  style = {{ width: "inherit", height:"inherit"}}/>
                        </div>
                        <div className="aging_potential_number">
                            <p>{props.aging_potential}</p>
                        </div>
                        <div className="grape_variety_icon">
                            <Grapes style = {{ width: "inherit", height:"inherit"}}/>
                        </div>
                        <div className="grape_variety_name">
                            <p>{props.grape_variety}</p>
                        </div>
                        {organic === 1 &&
                            <div className="organic">
                                <img src='./images/EU_Organic_Logo.jpg' alt="Organic" />
                            </div>
                        }       
                    </div>
                </div>
            </div>

            <div className="PriceAndButton">
                <p className="product_price"> {props.price} RON</p>
                <div className = "BuyButton">
                    <Button buttonSize="btn--medium" onClick={()=>handleClick(props)}>Cumpara</Button>
                </div>
            </div>
        </div>
    )
};


export default Product;