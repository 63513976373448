import { MenuItems } from "./MenuItems"
import './Navbar.css'
import logo from "../../images/logo1_noBackground1.png"
import { Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {CartState} from '../Context/Context'
import { useHistory } from "react-router-dom";




const Navbar = props => {
    
    function gotoHomePage() {
        history.push('/');
    }


    const {
        state: { cart },
      } = CartState();
    
    const history = useHistory();

      
    const [clicked, setClicked] = useState(false);
    const [bottles, setNrOfBottles] = useState(0);


    useEffect(() => {
        var nrOfBottles =  cart.reduce((acc, curr) => 
                    acc +  curr.qty, 0) ;
        setNrOfBottles(nrOfBottles);
    }, [cart]);

    return(
        <div className="Nav-Wrap">
            <nav className="NavbarItems" style={{position:"sticky"}}>
                <div className="menu-cart" >
                    <Link   className='nav-link-to-cart' to = "/CosulMeu">
                        <i className="fas fa-shopping-cart"></i>
                         {bottles > 0  &&  "( " + bottles.toString() + " )"}
                    </Link>
                </div>

                <div className="logo_menu">
                    <img src ={logo} onClick={gotoHomePage} width="147" height="147" alt="logo">
                    </img>
                </div>
                    
                <div className="menu-icon" onClick={setClicked}>
                    <i className={clicked ? 'fas fa-times' : 
                      'fas fa-bars'}></i>
                </div>
                    
                <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index} >
                                <Link   className={item.cName} 
                                to = {{pathname:item.url, state:{fromNavbar:true}}}  onClick={()=> setClicked()}>
                                    {item.id == 4 && bottles > 0 ? 
                                        item.title + "( " + bottles.toString() + " )" : item.title}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
        )
    }

export default Navbar;