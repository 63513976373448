import "./OrderComplete.css"
import { ShippingContext } from "../Context/ShippingContext";
import { useContext } from "react";


const OrderComplete = props => {

    const cart = props.cartCopy;

    const shippingPrice = useContext(ShippingContext);

    return (
        <div className="OrderComplete">
            <p>Multumim, comanda dumneavoastra a fost primita.</p>
            <ul>
                <li>
                    <h3>ID Comanda</h3>
                    <p>{props.orderNumber}</p>
                </li>
                <li>
                    <h3>Data</h3>
                    <p>
                        {props.date}
                    </p>
                </li>
                <li>
                    <h3>Total</h3>
                    <p>
                    {cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0) + shippingPrice} 
                    </p>
                </li>
                <li>
                    <h3>Metoda de plata</h3>
                    <p>La livrare</p>
                </li>
            </ul>
            <div className="OrderSummary">
                <table className="OrderSummaryTable">
                    <tbody>
                        <tr className="OrderSummaryTableRow">
                            <td className="OrderSummaryTableProductName">PRODUS</td>
                            <td className="OrderSummaryTableTotal">TOTAL</td>
                        </tr>

                        {cart.map( (prod) => (
                                <tr key={prod.id} className="OrderSummaryTableRow">
                                    <td className="OrderSummaryTableProductName">{prod.description}</td>
                                    <td className="OrderSummaryTableTotal">{prod.price*prod.qty} RON</td>
                                </tr>
                        ))}

                        <tr className="OrderSummaryTableRow">
                            <td className="OrderSummaryTableProductName">Livrare</td>
                            <td className="OrderSummaryTableTotal">{shippingPrice} RON</td>
                        </tr>
      
                        <tr className="OrderSummaryTableRow">
                            <td className="OrderSummaryTableGrandTotalText">ORDER TOTAL</td>
                            <td className="OrderSummaryTableGrandTotal"> {cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0) 
                            + shippingPrice} RON</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default OrderComplete;