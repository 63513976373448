import React from 'react';
import Navbar from './components/Navbar/Navbar'
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Products from './components/pages/Products';
import Footer from './components/pages/Footer';
//import Home from './components/pages/Home';
import Producers from './components/pages/Producers';
import Terms from './components/pages/Terms';
import AboutUs from './components/pages/AboutUs';
import CosulMeuOverall from './components/pages/CosulMeuOverall';
import { useState } from 'react';
import AreYouOver18Diag from './components/Dialogs/AreYouOver18Diag';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";

function App() {
  const [areYouOver18Dialog, setAreYouOver18Dialog] = useState(true);

  ReactPixel.init('538182364504102');
  ReactGA.initialize('G-4ND6H6JQMJ')

  return (
    <Router>
      <div className="App">
          <Navbar />
      </div>

      <Switch>
        <Route path='/Vinuri' component={Products} />
        {/*<Route path='/Home' component={Home} />*/}
        <Route path='/Artizani' component={Producers} />
        <Route path='/Terms' component={Terms} />
        <Route path='/About' component={AboutUs} />
        <Route path='/CosulMeu' component={CosulMeuOverall} />
        {/*<Route path='/CM2' component={CosulMeu2} />*/}
        <Route path='/' component={Products} /> 
      </Switch>
      <Footer />    

      {areYouOver18Dialog && <AreYouOver18Diag setOpenDiag={setAreYouOver18Dialog}/> }


    </Router>
  );
}

export default App;
